<template>
  <div class="home">
    <div style="margin-bottom: 125px">
      <img src="../assets/banner.png" alt="" />
    </div>
    <div class="link">
      <el-button link @click="to()">关于我们</el-button>
      <el-button link @click="toWheelSeedingPicture()">公司理念</el-button>
    </div>
    <div class="content">
      <div class="aboutUs" ref="aboutUs">
        <h3>众估宝</h3>
        <span class="introduction">
          <!-- <div class="content"> -->
          <span>&nbsp;&nbsp;&nbsp; 众估宝网络科技（上海）有限公司（以下简称：“众估宝”）由保亿家网络科技有限公司全资控股。</span><br />
          <span>&nbsp;&nbsp;&nbsp; 众估宝为保险公司提供可集中查看筛选全国各家公估公司综合服务能力，择优或竞价进行线上委托派件，并可一站式案件全流程监控和日常数据分析服务的服务平台。为保险公司在选择公估机构上提供多样性，弥补时效上及地域上的不足。</span><br />
          <span>&nbsp;&nbsp;&nbsp; 目前众估宝平台聚合公估公司23家，有效解决了合作保险公司、公估公司跨区域案件的委托和调查难点；截止2023年4月通过众估宝平台办理案件1659件，涉及总金额189484156元。</span><br /><br />
          <!-- <span>&nbsp;&nbsp;&nbsp; 众估宝技术服务亮点：</span><br /> -->
          <!-- <span>&nbsp;&nbsp;&nbsp; 一、流程再造：基于管理学思想和实际操作经验总结，梳理出一套完整科学的流程，极大提高服务时效性。</span><br />
          <span>&nbsp;&nbsp;&nbsp; 二、科技赋能：专门研发了公估应用工具，主要功能含：被调查人员的身份验证、公估人员的卫星地图追踪以及语音和视频资料的线上化采集等。</span><br />
          <span>&nbsp;&nbsp;&nbsp; 三、声谱分析：植入对采集的语音或视频资料的声谱分析功能，提供给合作保险公司作为判定案件调查结果可信性的参考依据。</span><br />
          <span>&nbsp;&nbsp;&nbsp; 四、数据分析：可根据保险公司需求，定期或不定期提供合作公估公司在平台承接案件的服务质量数据，比如结案时效、减损成效、投诉情况等等。</span> -->
          <!-- </div> -->
        </span>
        <!-- <div class="details">
          <span style="display: inline-block; width: 45%; font-size: 14px">众估宝打破了传统销售模式的“金字塔结构”，实现了扁平化管理，通过新型的组织分工与产品服务，有效降低运营成本，从而实现合作共赢。</span>
          <span style="display: inline-block; width: 45%; font-size: 14px">另一方面，以技术服务为核心向B端合作机构赋能，帮助合作机构快速实现金融智能化转型和业务落地，包括业务咨询、场景接入、决策支持、联合运营等多种服务；另一方面，以产品服务为核心向政企客户赋能，以“极客精神、极物标准、极速状态”将线上技术及线下服务有效结合，赋能政企采购电商化发展。 </span>
        </div> -->
      </div>
      <div class="wheelSeedingPicture" ref="wheelSeedingPicture">
        <div class="block text-center">
          <el-carousel height="500px">
            <el-carousel-item v-for="item in carouseData" :key="item">
              <img :src="item.url" style="width: 100%; height: 100%" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="btn">联系我们</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutUs",
  data() {
    return {
      carouseData: [
        {
          url: require("@/assets/us1.png"),
        },
        {
          url: require("@/assets/us2.png"),
        },
        {
          url: require("@/assets/us1.png"),
        },
      ],
    };
  },
  methods: {
    to() {
      this.$refs["aboutUs"].scrollIntoView(true);
    },
    toWheelSeedingPicture() {
      this.$refs["wheelSeedingPicture"].scrollIntoView(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding-bottom: 50px;
  overflow-x: hidden;
}
.link {
  width: 600px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 20px;
}

.content {
  display: block;
  border-top: 1px solid #d2d2d2;

  .aboutUs {
    padding-top: 80px;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    margin-bottom: 100px;
    .introduction {
      font-size: 16px;
      font-weight: 600;
      span {
        display: block;
        text-align: justify;
      }
    }
  }
  .details {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 50px;
  }
  .wheelSeedingPicture {
    margin-bottom: 60px;
  }
  .btn {
    display: inline-block;
    cursor: pointer;
    padding: 13px 70px;
    border: 1px solid #b5b5b5;
    color: #000000;
    font-size: 14px;
    text-align: center;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .btn:hover {
    color: white;
    background-color: #000000;
  }
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
