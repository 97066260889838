<template>
  <div class="home">
    <div class="wheelSeedingPicture">
      <div class="block text-center">
        <el-carousel height="540px">
          <el-carousel-item v-for="item in carouseData" :key="item">
            <!-- <img :src="'http://192.168.3.85:8080' + item.img" style="width: 100%; height: 100%" alt="" /> -->
            <img :src="'https://zgbow.baoyj.net/prod-api' + item.img" style="width: 100%; height: 100%" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="companyIntroduction">
      <h1 class="name">公司介绍</h1>
      <div class="underline"></div>
      <div class="content">
        <span>&nbsp;&nbsp;&nbsp; 众估宝网络科技（上海）有限公司（以下简称：“众估宝”）由保亿家网络科技有限公司全资控股。</span><br />
        <span>&nbsp;&nbsp;&nbsp; 众估宝为保险公司提供可集中查看筛选全国各家公估公司综合服务能力，择优或竞价进行线上委托派件，并可一站式案件全流程监控和日常数据分析服务的服务平台。为保险公司在选择公估机构上提供多样性，弥补时效上及地域上的不足。</span><br />
        <span>&nbsp;&nbsp;&nbsp; 目前众估宝平台聚合公估公司23家，有效解决了合作保险公司、公估公司跨区域案件的委托和调查难点；截止2023年4月通过众估宝平台办理案件1659件，涉及总金额189484156元。</span><br /><br /><br />
        <span>&nbsp;&nbsp;&nbsp; 众估宝技术服务亮点：</span><br />
        <span>&nbsp;&nbsp;&nbsp; 一、流程再造：基于管理学思想和实际操作经验总结，梳理出一套完整科学的流程，极大提高服务时效性。</span><br />
        <span>&nbsp;&nbsp;&nbsp; 二、科技赋能：专门研发了公估应用工具，主要功能含：被调查人员的身份验证、公估人员的卫星地图追踪以及语音和视频资料的线上化采集等。</span><br />
        <span>&nbsp;&nbsp;&nbsp; 三、声谱分析：植入对采集的语音或视频资料的声谱分析功能，提供给合作保险公司作为判定案件调查结果可信性的参考依据。</span><br />
        <span>&nbsp;&nbsp;&nbsp; 四、数据分析：可根据保险公司需求，定期或不定期提供合作公估公司在平台承接案件的服务质量数据，比如结案时效、减损成效、投诉情况等等。</span>
      </div>
    </div>
    <!-- <div class="serviceIntroduction">
      <h1 class="name">业务介绍</h1>
      <div class="underline"></div>
      <div class="content">
        <div class="content-1">
          <div class="icon">
            <img src="../assets/content1.png" alt="" />
          </div>
          <div class="title">公司自营E优商城</div>
          <div class="underline"></div>
          <span> E优商城品类丰富，有蔬果鲜食、日用百货、家用电器、礼盒用品、农特产品等产品，每逢特定节日，推出特惠专题活动，随着趋势发展，商城推出直播活动，挑选好物每场不定时放送给直播间观众。 </span>
        </div>
        <div class="content-2">
          <div class="icon">
            <img src="../assets/content2.png" alt="" />
          </div>
          <div class="title">线上推广</div>
          <div class="underline"></div>
          <span> 采用广告投放的方式，帮助保险公司做好公司品牌、险种、营销活动的推广。 </span>
          <div class="details">
            <div class="details-1" v-for="(item, index) in details" :key="index">
              {{ item }}
            </div>
          </div>
        </div>
        <div class="content-3">
          <div class="icon">
            <img src="../assets/content3.png" alt="" />
          </div>
          <div class="title">线下推广</div>
          <div class="underline"></div>
          <span> 1、保亿家网络科技有限公司凭借在市场营销活动策划和推广领域丰富的服务经验，在软件技术开发和运维上的成熟团队操作模式以及在新兴平台化管理上的行业领先优势，承办线下推广合作广告项目。 </span>
        </div>
        <div class="content-4">
          <div class="icon">
            <img src="../assets/content3.png" alt="" />
          </div>
          <div style="margin-top: 13px">
            <img style="width: 145px; height: 20px" src="../assets/title1.png" alt="" />
          </div>
          <div class="underline"></div>
          <div>
            <span style="display: inline-block; font-size: 14px; margin-bottom: 10px"> 权益兑换平台“线上暖心服务”为例 </span><br />
            <span style="display: inline-block; font-size: 14px; color: #ce1a1b; margin-bottom: 10px"> 二、平台模块和主要活动 </span><br />
            <span style="font-size: 14px">手机充值专区、一键加油专区、影音娱乐专区、 电子礼品专区、热门推荐专区、快速充值专区</span>
          </div>
        </div>
        <div class="content-5">
          <div class="icon">
            <img src="../assets/content5.png" alt="" />
          </div>
          <div style="margin-top: 13px">
            <img style="width: 145px; height: 20px" src="../assets/title2.png" alt="" />
          </div>
          <div class="underline"></div>
          <div>
            <span> 章鱼模玩以全新的视角带你领略模型玩具的精彩魅力，在这里你将能看到酷炫的机动战士高达、神奇的变形金刚、精细的可动人偶等精美的模型玩具。 </span>
          </div>
        </div>
        <div class="content-6">
          <div class="icon">
            <img src="../assets/content6.png" alt="" />
          </div>
          <div style="margin-top: 13px">
            <img style="width: 145px; height: 20px" src="../assets/title3.png" alt="" />
          </div>
          <div class="underline"></div>
          <div>
            <span>推广成功后，用户的各项数据、推广IP、区域、手机号码、日期等详细资料统一生成。 我公司与上万家媒体组成线上媒体联盟，全力推动合作单位市场推广需求。下表为简单列举部分媒体列表清单，后续可根据客户稿件、图文情况重新规划发布媒体。</span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="advertisingCenter">
      <h1 class="name">广告中心</h1>
      <div class="underline"></div>
      <div class="advertisingCenter-content">
        <div class="advertisingCenter-l">
          <div class="advertisingCenter-l-t">
            <div class="advertisingCenter-l-t-l">
              <div class="companyDynamics">
                <span style="font-size: 24px; color: #ffffff">公司动态</span>
                <span style="font-size: 18px; opacity: 0.2; color: #ffffff; margin-top: 18px">COMPANY DYNAMIC</span>
              </div>
            </div>
            <div class="companyDynamicsContent">
              <div class="separateNews" v-for="(item, index) of trends" :key="index" @click="checkNews(item.content)" style="cursor: pointer">
                <div class="title">
                  <div class="status-point"></div>
                  <span>{{ item.navigationName }}</span>
                </div>
                <div class="time">{{ item.createTime }}</div>
              </div>
            </div>
          </div>
          <div class="advertisingCenter-l-b">
            <div class="advertisingCenter-l-b-l">
              <div class="tradeNews">
                <span style="font-size: 24px; color: #ffffff">行业新闻</span>
                <span style="font-size: 18px; opacity: 0.2; color: #ffffff; margin-top: 18px">INDUSTRY NEWS </span>
              </div>
            </div>
            <div class="tradeNewsContent">
              <div class="separateNews" v-for="(item, index) of industryNews" :key="index" @click="checkNews(item.content)" style="cursor: pointer">
                <div class="title">
                  <div class="status-point"></div>
                  <span>{{ item.navigationName }}</span>
                </div>
                <div class="time">{{ item.createTime }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="advertisingCenter-r"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { bannerApi } from "@/api/index";
export default {
  name: "HomeView",
  data() {
    return {
      carouseData: [],
      details: ["自营商店", "小程序", "抖音号", "公众号", "短信推广", "网络媒体"],
      trends: [],
      industryNews: [],
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    getBanner() {
      bannerApi().then((response) => {
        this.carouseData = response.enavigations[0].navigationChild;
        this.trends = response.enavigations[3].navigationChild[0].navigationChild;
        this.industryNews = response.enavigations[3].navigationChild[1].navigationChild;
      });
    },
    checkNews(content) {
      this.$router.push("/news_show");
      //this.$store.dispatch('user/setContent', data)
      sessionStorage.setItem("content", content);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding-bottom: 100px;
}
.wheelSeedingPicture {
  margin-bottom: 79px;
  .text-center {
    width: 1352px;
    height: 540px;
    margin: 0 auto;
  }
}
.companyIntroduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  .name {
    font-size: 36px;
    font-weight: 500;
    color: #e21111;
  }
  .underline {
    width: 60px;
    height: 4px;
    background-color: #e21111;
    margin-bottom: 31px;
  }
  .content {
    width: 1192px;

    font-size: 24px;

    font-weight: 400;

    line-height: 40px;
  }
}
.serviceIntroduction {
  height: 1050px;
  background: url(../assets/bgc.png);
  display: flex;
  flex-direction: column;
  align-items: center;
  .name {
    font-size: 36px;
    font-weight: 500;
    color: #e21111;
    margin-top: 100px;
  }
  .underline {
    width: 60px;
    height: 4px;
    background-color: #e21111;
    margin-bottom: 69px;
  }
  .content {
    width: 965px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .content-1 {
      width: 300px;
      height: 350px;
      background-color: #e11515;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 30px;
      box-sizing: border-box;
      margin-bottom: 33px;
      .icon {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        border: 1px solid white;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 31px;
          height: 38px;
        }
      }
      .title {
        font-size: 24px;
        font-weight: bold;

        color: #ffffff;
        line-height: 20px;
        margin-top: 15px;
      }
      .underline {
        width: 30px;
        height: 2px;
        background: white;
        margin-top: 16px;
        margin-bottom: 31px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
      }
    }
    .content-2 {
      width: 300px;
      height: 350px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 25px;
      border-bottom: 2px solid #e11515;
      box-sizing: border-box;
      margin-bottom: 33px;
      .icon {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        border: 1px solid #e9e9e9;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 35.28px;
          height: 35.28px;
        }
      }
      .title {
        font-size: 24px;
        font-weight: bold;

        color: #de1e1e;
        line-height: 20px;
        margin-top: 15px;
      }
      .underline {
        width: 30px;
        height: 2px;
        background: #de1e1e;
        margin-top: 16px;
        margin-bottom: 31px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #221815;
        line-height: 22px;
        margin-bottom: 5px;
      }
      .details {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .details-1 {
          width: 75px;
          height: 18px;
          border: 1px solid #ff0000;
          border-radius: 9px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
          text-align: center;
          margin-top: 15px;
        }
      }
    }
    .content-3 {
      width: 300px;
      height: 350px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 25px;
      border-bottom: 2px solid #e11515;
      box-sizing: border-box;
      margin-bottom: 33px;
      .icon {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        border: 1px solid #e9e9e9;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 35.28px;
          height: 35.28px;
        }
      }
      .title {
        font-size: 24px;
        font-weight: bold;

        color: #de1e1e;
        line-height: 20px;
        margin-top: 15px;
      }
      .underline {
        width: 30px;
        height: 2px;
        background: #de1e1e;
        margin-top: 16px;
        margin-bottom: 31px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #221815;
        line-height: 22px;
        margin-bottom: 5px;
      }
    }
    .content-4 {
      width: 300px;
      height: 350px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 25px;
      border-bottom: 2px solid #e11515;
      box-sizing: border-box;
      .icon {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        border: 1px solid #e9e9e9;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 35.28px;
          height: 35.28px;
        }
      }
      .title {
        font-size: 24px;
        font-weight: bold;

        color: #de1e1e;
        line-height: 20px;
        margin-top: 15px;
      }
      .underline {
        width: 30px;
        height: 2px;
        background: #de1e1e;
        margin-top: 16px;
        margin-bottom: 21px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #221815;
        line-height: 22px;
        margin-bottom: 5px;
      }
    }
    .content-5 {
      width: 300px;
      height: 350px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 25px;
      border-bottom: 2px solid #e11515;
      box-sizing: border-box;
      .icon {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        border: 1px solid #e9e9e9;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 35.28px;
          height: 35.28px;
        }
      }
      .title {
        font-size: 24px;
        font-weight: bold;

        color: #de1e1e;
        line-height: 20px;
        margin-top: 15px;
      }
      .underline {
        width: 30px;
        height: 2px;
        background: #de1e1e;
        margin-top: 16px;
        margin-bottom: 21px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #221815;
        line-height: 22px;
        margin-bottom: 5px;
      }
    }

    .content-6 {
      width: 300px;
      height: 350px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 25px;
      border-bottom: 2px solid #e11515;
      box-sizing: border-box;
      .icon {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        border: 1px solid #e9e9e9;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 35.28px;
          height: 35.28px;
        }
      }
      .title {
        font-size: 24px;
        font-weight: bold;

        color: #de1e1e;
        line-height: 20px;
        margin-top: 15px;
      }
      .underline {
        width: 30px;
        height: 2px;
        background: #de1e1e;
        margin-top: 16px;
        margin-bottom: 21px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #221815;
        line-height: 22px;
        margin-bottom: 5px;
      }
    }
  }
}

.advertisingCenter {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .name {
    font-size: 36px;
    font-weight: 500;
    color: #e21111;
  }
  .underline {
    width: 60px;
    height: 4px;
    background-color: #e21111;
    margin-bottom: 31px;
  }
  .advertisingCenter-content {
    width: 1201px;
    display: flex;
    justify-content: space-between;
    .advertisingCenter-l {
      width: 752px;
      height: 394px;
      display: flex;
      flex-direction: column;
      .advertisingCenter-l-t {
        width: 762px;
        height: 50%;
        display: flex;
        .advertisingCenter-l-t-l {
          width: 214px;
          height: 100%;
          .companyDynamics {
            width: 100%;
            height: 100%;
            background: url(../assets/red.png);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
        .companyDynamicsContent {
          width: 548px;
          height: 100%;
          border: 1px solid #eaeaea;
          border-left: none;
          border-bottom: none;
          box-sizing: border-box;
          position: relative;
          left: -10px;
          padding: 0 20px;
          box-sizing: border-box;
          overflow-y: auto;
          .list-wrapper::-webkit-scrollbar {
            height: 0;
            width: 0;
          }
          .separateNews {
            display: flex;
            justify-content: space-between;
            padding-top: 18px;

            .title {
              border-bottom: 1px dashed #e6e6e6;
              padding-bottom: 18px;
              display: flex;
              align-items: center;
              .status-point {
                display: inline-block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #8d8d8d;
                font-size: 14px;
                color: #323232;
                margin-right: 12px;
              }
            }
            .time {
              font-size: 14px;
              color: #909090;
              margin-top: 3px;
            }
          }
        }
      }
      .advertisingCenter-l-b {
        width: 762px;
        height: 50%;
        display: flex;
        .advertisingCenter-l-b-l {
          width: 214px;
          height: 100%;
          .tradeNews {
            width: 100%;
            height: 100%;
            background: url(../assets/grey.png);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
        .tradeNewsContent {
          width: 548px;
          height: 100%;
          border: 1px solid #eaeaea;
          border-left: none;
          border-top: none;
          box-sizing: border-box;
          position: relative;
          left: -10px;
          padding: 0 20px;
          box-sizing: border-box;
          overflow-y: auto;
          .list-wrapper::-webkit-scrollbar {
            height: 0;
            width: 0;
          }
          .separateNews {
            display: flex;
            justify-content: space-between;
            padding-top: 18px;

            .title {
              border-bottom: 1px dashed #e6e6e6;
              padding-bottom: 18px;
              display: flex;
              align-items: center;
              .status-point {
                display: inline-block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #8d8d8d;
                font-size: 14px;
                color: #323232;
                margin-right: 12px;
              }
            }
            .time {
              font-size: 14px;
              color: #909090;
              margin-top: 3px;
            }
          }
        }
      }
    }
    .advertisingCenter-r {
      width: 428px;
      height: 394px;
      background: url(../assets/advertisement.png);
    }
  }
}
</style>
