<template>
  <div id="app">
    <div class="top">
      <div class="logo">
        <img src="./assets/logored.png" alt="" />
      </div>
      <div class="menu">
        <div :class="menuIndex == index ? 'menu-box-active' : 'menu-box'" v-for="(item, index) in menuData" :key="index" @click="selMenu(index)">
          <div class="menu-title">{{ item.title }}</div>
          <div class="menu-sel"></div>
        </div>
      </div>
    </div>
    <router-view />

    <div class="footer">
      <div class="footer-left">
        <div class="footer-link">
          <div class="footer-link-title">友情链接</div>
          <div>中国人民保险</div>
          <div>泰山保险</div>
        </div>
        <div class="footer-info">
          <div class="footer-info-box">
            <img class="logo" src="./assets/logo.png" alt="" />
          </div>
          <div class="footer-info-box">
            <img class="icon" src="./assets/phone.png" alt="" />
            <div style="margin-left: 15px; height: 36px">
              <div>服务热线</div>
              <!-- <div style="font-size: 17px;">15606638071</div> -->
              <div style="font-size: 17px">13917727518</div>
            </div>
          </div>
          <div class="footer-info-box" style="border-right: 0">
            <img class="icon" src="./assets/phone.png" alt="" />
            <div style="margin-left: 15px; height: 36px">
              <div>服务地址</div>
              <div style="font-size: 16px">上海市浦东新区惠南镇禹州商业广场3号楼1105室</div>
              <!-- <div style="font-size: 16px;">浙江省龙湾区兴区路55号北航大厦12楼</div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="footer-right">
        <img class="vcode" src="./assets/vcode.png" alt="" />
        <div style="margin-top: 20px">
          <img class="icon" src="./assets/qq.png" alt="" />
          <img class="icon" src="./assets/wx.png" alt="" />
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menuStyle: "menu-box",
      menuData: [
        {
          title: "首页",
          menuStyle: "menu-box",
        },
        {
          title: "关于我们",
          menuStyle: "menu-box",
        },
        {
          title: "联系我们",
          menuStyle: "menu-box",
        },
        {
          title: "广告中心",
          menuStyle: "menu-box",
        },
      ],
      menuIndex: 0,
    };
  },
  created() {
    this.menuIndex = JSON.parse(window.sessionStorage.getItem("index"));
  },
  methods: {
    selMenu(idx) {
      this.menuIndex = idx;
      switch (idx) {
        case 0:
          this.$router.push("/");
          break;
        case 1:
          this.$router.push("/us");
          break;
        case 2:
          this.$router.push("/contact");
          break;
        case 3:
          this.$router.push("/news");
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  padding: 0px;
  margin: 0px;
}
html {
  height: 100vh;
  padding: 0px;
  margin: 0px;
}

.top {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #d2d2d2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .logo {
    height: 70%;
    position: absolute;
    left: 15%;
    img {
      height: 100%;
    }
  }
  .menu {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .menu-box {
      margin: 0 20px;
      height: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      white-space: nowrap;
      .menu-sel {
        transform: scale(0, 1);
        transition: 0.5s;
        width: 100%;
        height: 7px;
        position: absolute;
        bottom: -1px;
        background: #de1e1e;
      }
    }

    .menu-box-active {
      white-space: nowrap;
      margin: 0 20px;
      height: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      .menu-sel {
        width: 100%;
        height: 7px;
        position: absolute;
        bottom: -1px;
        background: #de1e1e;
      }
    }

    .menu-box:hover {
      .menu-sel {
        transform: scale(1, 1);
        width: 100%;
      }
    }
  }
}

.footer {
  width: 100%;
  height: 300px;
  background: #333333;
  color: #333333;
  padding: 0 40px;
  box-sizing: border-box;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  .footer-left {
    width: 40%;
    height: 100%;
    .footer-link {
      width: 30%;
      height: 50%;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 25px;
      .footer-link-title {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        width: 70%;
        text-align: center;
        padding: 10px 0;
        margin-bottom: 10px;
      }
    }
    .footer-info {
      width: 100%;
      height: 20%;
      display: flex;
      .footer-info-box {
        color: white;
        width: 33%;
        display: flex;
        align-items: center;
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        margin-left: 10px;
        .logo {
          height: 100%;
        }
        .icon {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
  .footer-right {
    width: 15%;
    height: 80%;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    .vcode {
      width: 146px;
      height: 146px;
    }
    .icon {
      width: 38px;
      height: 38px;
    }
  }
}
</style>
