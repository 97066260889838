import request from '@/utils/request'

// 编辑留言栏
export function messageEditApi(data) {
    return request({
      url: '/leave/add',
      method: 'post',
      data
    })
}

// 编辑留言栏
export function bannerApi() {
  return request({
    url: '/zgbOfficialWebsite',
    method: 'get'
  })
}