import axios from 'axios'

const service = axios.create({
  baseURL:process.env.VUE_APP_BASE_API, 
  timeout: 30000 // request timeout
})
// request interceptor
service.interceptors.request.use(config => {
    if(config && config.headers){
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
    }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(response => {
    const res = response.data
    return res
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
