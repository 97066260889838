const state = {
  content: ''
}

const mutations = {
  SET_CONTENT: (state, content) => {
    state.content = content
  },
}

const actions = {
  setContent({commit}, data){
    console.log(data)
    commit('SET_CONTENT', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

