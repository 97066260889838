<template>
    <div class="new_show">
        <div id="newShwo" class="newsBody"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pageBody: '',
        }
    },
    mounted() {
        this.pageInit()
    },
    created() {
        // console.log(this.$store.getters.content)
        console.log(sessionStorage.getItem('content'))
    },
    methods: {
        pageInit() {
            var reg = new RegExp("/dev-api", "g")
            let content = sessionStorage.getItem('content').replace(reg, "http://192.168.3.85:8080")
            console.log(content)
            let body = document.getElementById('newShwo')
            body.insertAdjacentHTML("afterbegin", content)
        }
    }
}
</script>

<style lang="scss" scoped>
p {
    border: 1px solid red;
}
.new_show {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .newsBody {
        width: 80%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            border: 1px solid red !important;
        }
    }
}
</style>