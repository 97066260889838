<template>
  <div class="contact">
    <div class="contact-top">
      <div id="allmap" class="allmap" />
      <div class="contactFuc">
        <div class="title">联系方式</div>
        联系方式：13917727518<br />
        <!-- 电话：4008600067<br /> -->
        <!-- 邮箱：admin@baoyj.net<br /> -->
        地址：上海市浦东新区惠南镇禹州商业广场3号楼1105室<br />
      </div>
    </div>
    <div class="form-title" style="margin-top: 70px; font-size: 20px; font-weight: bold">留言栏</div>
    <el-form :model="form" label-width="120px" style="margin-top: 40px">
      <el-form-item label="姓名">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="邮箱">
        <el-input v-model="form.email" />
      </el-form-item>
      <el-form-item label="电话">
        <el-input v-model="form.mobile" />
      </el-form-item>
      <el-form-item label="省市">
        <el-input v-model="form.area" />
      </el-form-item>
      <el-form-item label="留意类别">
        <el-radio-group v-model="form.type">
          <el-radio :label="0">合作意向</el-radio>
          <el-radio :label="1">加入我们</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="留意内容">
        <el-input v-model="form.content" type="textarea" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="sentMessage()">提交</el-button>
        <el-button>清空</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { BMapLoader } from "@/utils/map";
import { messageEditApi } from "@/api/index";
export default {
  data() {
    return {
      map: null,
      form: {
        name: "",
        email: "",
        mobile: "",
        area: "",
        type: 0,
        content: "",
      },
    };
  },
  created() {
    this.initMap();
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      this.$nextTick(() => {
        BMapLoader("oxlvdUxV5KHd4wDyGOGbsKGZVgQn4iTz").then((BMap) => {
          const map = new BMap.Map("allmap", { enableMapClick: false });
          this.map = map;
          map.disableKeyboard(); // 禁用键盘操作地图
          map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
          // 添加中心点和缩放等级，如果不添加，初始化出来页面为白屏
          const point = new BMap.Point(106.78887812364171, 29.666046706760273);
          map.centerAndZoom(point, 15);
        });
      });
    },
    sentMessage() {
      messageEditApi(this.form).then((response) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  padding: 50px 400px;
  display: flex;

  flex-direction: column;
}
.contact-top {
  width: 100%;
  display: flex;
}
.allmap {
  width: 780px;
  height: 600px;
}

.contactFuc {
  height: 600px;
  line-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  font-size: 14px;
  .title {
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
