<template>
  <div class="news">
    <img class="topimg" src="../assets/advertisement.png" alt="" />
    <div class="search">
      <img src="../assets/search.png" alt="" />
      <el-input v-model="input" placeholder="" />
    </div>

    <div class="contact">
      <el-tabs tab-position="left">
        <el-tab-pane label="公司动态">
          <div v-for="(item, index) in trends" :key="index" style="margin-top: 40px;cursor:pointer" @click="checkNews(item.content)">
            <img :src="'http://192.168.3.85:8080' + item.img" alt="" />
            <div class="title" style="margin-top: 10px;">
                {{item.navigationName}}
            </div>
            <div class="time" style="margin-top: 10px;">
                {{ item.createTime }}
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="行业新闻">
          <div v-for="(item, index) in industryNews" :key="index" style="margin-top: 40px;cursor:pointer"  @click="checkNews(item.content)">
            <img :src="'http://192.168.3.85:8080' + item.img" alt="" />
            <div class="title" style="margin-top: 10px;">
                {{item.navigationName}}
            </div>
            <div class="time" style="margin-top: 10px;">
                {{ item.createTime }}
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { MoreFilled } from "@element-plus/icons-vue";
import { bannerApi } from '@/api/index'
export default {
  data() {
    return {
      trends: [],
      industryNews: [],
      activities: [
        {
          content: "Custom icon",
          timestamp: "2018-04-12 20:46",
          size: "large",
          type: "primary",
          icon: MoreFilled,
        },
        {
          content: "Custom color",
          timestamp: "2018-04-03 20:46",
          color: "#0bbd87",
        },
        {
          content: "Custom size",
          timestamp: "2018-04-03 20:46",
          size: "large",
        },
        {
          content: "Custom hollow",
          timestamp: "2018-04-03 20:46",
          type: "primary",
          hollow: true,
        },
        {
          content: "Default node",
          timestamp: "2018-04-03 20:46",
        },
      ],
    };
  },
  mounted() {
    this.getBanner()
  },
  methods: {
    getBanner() {
      bannerApi().then(response => {
        this.trends = response.enavigations[3].navigationChild[0].navigationChild
        this.industryNews = response.enavigations[3].navigationChild[1].navigationChild
      })
    },
    checkNews(content) {
      this.$router.push('/news_show')
      //this.$store.dispatch('user/setContent', data)
      sessionStorage.setItem('content', content)
    }
  }
};
</script>

<style lang="scss" scoped>
.news {
  display: flex;

  flex-direction: column;
  align-items: center;
}
.topimg {
  width: 100%;
  height: 400px;
}
.search {
  width: 60%;
  height: 100px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.contact {
  width: 80%;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 100px;
}
</style>