import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Contact from '../views/Contact.vue'
import News from '../views/news.vue'
import newsShow from '../views/news_show.vue'
import aboutUs from '../views/aboutUs.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: '首页', index: 0, affix: true }
  },
  {
    path: '/us',
    component: aboutUs,
    meta: { title: '关于我们',  index: 1, affix: true }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: { title: '联系我们',  index: 2, affix: true }
  },
  {
    path: '/news',
    name: 'news',
    component: News,
    meta: { title: '广告中心',  index: 3, affix: true }
  },
  {
    path: '/news_show',
    name: 'newsShow',
    component: newsShow,
    meta: { title: '新闻',  index: 3, affix: true }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
